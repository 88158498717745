<template>
  <div>
    <div class="banner software-banner">
      <div class="banner-item">{{ item }}</div>
    </div>

    <div class="container row-between" style="position: relative">
      <div class="show show-margin" v-if="asideTab == 0">
        <div class="show-title">
          <div class="show-title-t1">SaaS系统</div>
          <div class="show-title-img" style="margin-bottom: 30px">
            <img src="../../assets/img/software/software-1.png" alt="" />
          </div>
          <div class="show-title-p">
            小松鼠所有物联网智能设备采用SaaS系统进行分布式管理。不同地域、不同用户都有拥有独立管理的数据平台、互不干扰。
            小松鼠环保SaaS平台根据国内最新垃圾分类的管理要求开发，能精准收集每一位用户的环保数据，又能汇总输出进行分析对比，同时通过对系统的使用还能对所有设备进行远程的操作管理，定期维护。
          </div>
          <div class="show-btn bg-shadow">
            <div class="bg-active"></div>
            <div
              class="news-img-t1-sp news-img-t1-center news-more1"
              style="text-align: center"
            >
              了解更多<img
                src="../../assets/img/news-more1.png"
                alt=""
                class="arrow-white arrow-white-t1"
              /><img
                src="../../assets/img/news-more3.png"
                alt=""
                class="arrow-white arrow-white-t2"
              />
            </div>
          </div>
        </div>

        <div class="show-title">
          <div class="show-title-t1">政府垃圾分类地方管理系统</div>
          <div class="show-title-img" style="margin-bottom: 30px">
            <img src="../../assets/img/software/software-2.png" alt="" />
          </div>
          <div class="show-title-p">
            对学校、机关单位、小区、农村回收分拣点进行管理，支持智慧古村系统对辖区居民的回收，积分兑换、礼品管理、活动管理及支持用户查询附近的回收点及智能回收机。
          </div>
          <div class="show-btn bg-shadow">
            <div class="bg-active"></div>
            <div
              class="news-img-t1-sp news-img-t1-center news-more1"
              style="text-align: center"
            >
              了解更多<img
                src="../../assets/img/news-more1.png"
                alt=""
                class="arrow-white arrow-white-t1"
              /><img
                src="../../assets/img/news-more3.png"
                alt=""
                class="arrow-white arrow-white-t2"
              />
            </div>
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1">大数据平台</div>
          <div class="show-title-p">
            大数据可视化平台是对垃圾分类回收生态链的实时数据监测和全面监管，数据可为智慧城市的建设和政府决策提供依据。
          </div>
          <div class="show-title-img" style="margin-bottom: 30px">
            <img src="../../assets/img/service/service-3.png" alt="" />
          </div>
          <div class="show-title-p">
            <div>
              <div class="item-color show-title-se">「用户的集中管理」</div>
              <p>
                对垃圾分类参与用户进行集中管理，并对其行为习惯进行人像分析。
              </p>
            </div>
            <div>
              <div class="item-color show-title-se">「数据的实时监测」</div>
              <p>
                对回收机运行状态进行实时监测，实时统计垃圾类别和数量，并进行满箱预警、故障报警、物流跟踪等。
              </p>
            </div>
            <div>
              <div class="item-color show-title-se">「运维的资源整合」</div>
              <p>
                对垃圾分类的收集、运输、处理环节进行统一整合，提高运营效率，降低人员成本。
              </p>
            </div>
          </div>
          <div class="show-btn bg-shadow">
            <div class="bg-active"></div>
            <div
              class="news-img-t1-sp news-img-t1-center news-more1"
              style="text-align: center"
            >
              了解更多<img
                src="../../assets/img/news-more1.png"
                alt=""
                class="arrow-white arrow-white-t1"
              /><img
                src="../../assets/img/news-more3.png"
                alt=""
                class="arrow-white arrow-white-t2"
              />
            </div>
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1">可回收垃圾系统</div>
          <div>
            <div class="item-color show-title-third">小松鼠智能回收程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-3.png" alt="" />
            </div>
            <div class="show-title-p">
              小松鼠采用环保金账户模式，用户只需要在小松鼠小程序或回收机上进行账户登陆，即可实现垃圾投递，实时获取环保金。环保金是小松鼠对用户参与垃圾分类回收的一种资金奖励，根据用户投放种类和数量，发放相应的环保金。用户可对环保金进行微信提现或参与活动
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">投递用户小程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-4.png" alt="" />
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">回收员小程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-5.png" alt="" />
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">回收站小程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-6.png" alt="" />
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">回收商小程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-7.png" alt="" />
            </div>
          </div>
        </div>
        <div class="show-title">
          <div class="show-title-t1">干湿垃圾系统</div>
          <div class="show-title-img">
            <img src="../../assets/img/software/software-8.png" alt="" />
          </div>
          <div class="show-title-p">
            通过系统管理用户的垃圾投递行为，对用户的投递信息进行记录和上报。包括是否合规投递垃圾、投递重量、积分获得，能够按设备点位划分不同区域，进行一体化的数据呈现及分析管理。
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 1">
        <div class="show-title">
          <div class="show-title-t1">SaaS系统</div>
          <div class="show-title-third item-color" style="font-size: 20px">
            自建共享型互联网大数据智慧云平台
          </div>
          <div class="show-title-p">
            为城市垃圾分类体系建立一套独立的SaaS化管理系统，实现各小区、各社区、各街道垃圾分类数据统计、分析、排行，为垃圾减量提供实时准确的数据支撑。
          </div>
          <div>
            <div class="show-title-img-sps">
              <img :src="sassBg[sassIndex].bg" alt="" />
            </div>
            <div class="row-between">
              <div
                class="show-sass"
                v-for="item in sassBg"
                :key="item.id"
                @click="sassBgChange(item.id)"
                :class="sassIndex == item.id ? 'show-sass-sp' : ''"
              >
                <img :src="item.bg" alt="" />
              </div>
            </div>
            <div class="row-between">
              <div class="show-sass-li" v-for="item in sassLi" :key="item.id">
                <div class="show-sass-li-img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="show-sass-li-t1">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 2">
        <div class="show-title">
          <div class="show-title-t1">政府垃圾分类地方管理系统</div>
          <div class="show-title-p">
            <p>
              对垃圾分类回收生态链的实时数据监测和全面监管，数据可为智慧城市的建设和政府决策提供依据。实现用户的集中管理，对垃圾分类参与用户进行集中管理，并对其行为习惯进行人像分析。数据的实时监测，对回收机运行状态进行实时监测，实时统计垃圾类别和数量，并进行满箱预警、故障报警、物流跟踪等。
            </p>
            <p>
              运维的资源整合，对垃圾分类的收集、运输、处理环节进行统一整合，提高运营效率，降低人员成本。
            </p>
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/software/software-2.png" alt="" />
          </div>
          <div class="row-between">
            <div class="show-system">
              <div class="show-system-img">
                <img src="../../assets/img/software/software-gov1.png" alt="" />
              </div>
              <div class="show-system-t1">用户数据统计</div>
            </div>
            <div class="show-system">
              <div class="show-system-img">
                <img src="../../assets/img/software/software-gov2.png" alt="" />
              </div>
              <div class="show-system-t1">满箱预警</div>
            </div>
            <div class="show-system">
              <div class="show-system-img">
                <img src="../../assets/img/software/software-gov3.png" alt="" />
              </div>
              <div class="show-system-t1">故障报警</div>
            </div>
            <div class="show-system">
              <div class="show-system-img">
                <img src="../../assets/img/software/software-gov4.png" alt="" />
              </div>
              <div class="show-system-t1">物流追踪</div>
            </div>
          </div>
          <div class="item-color show-title-third" style="margin-top: 50px">
            定制化解决方案
          </div>
          <div class="show-title-p" style="margin-top: 10px">
            小松鼠针对政府各类业务需求和和应用场景，定制专业解决方案
          </div>
          <div class="show-title-img row-between">
            <div
              class="show-shadow show-shadow-sp3"
              v-for="item in business"
              :key="item.id"
              :style="'background-image: url(' + item.bgImg + ');'"
            >
              <div class="show-shadow-cont">
                <div class="show-shadow-img">
                  <img :src="item.img" alt="" />
                </div>
                <div
                  class="show-shadow-t1"
                  v-html="item.featureName"
                  style="line-height: 21px"
                ></div>
              </div>
              <div class="show-shadow-bg show-shadow-bg-t3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 3">
        <div class="show-title">
          <div class="show-title-t1">大数据平台</div>
          <div class="show-title-third" style="font-size: 20px"></div>
          <div class="show-title-p">
            为城市垃圾分类体系建立一套独立的SaaS化管理系统，实现各小区、各社区、各街道垃圾分类数据统计、分析、排行，为垃圾减量提供实时准确的数据支撑。
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/service/service-3.png" alt="" />
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 4">
        <div class="show-title">
          <div class="show-title-t1">可回收垃圾系统</div>
          <div>
            <div class="item-color show-title-third">小松鼠智能回收程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-3.png" alt="" />
            </div>
            <div class="show-title-p">
              小松鼠采用环保金账户模式，用户只需要在小松鼠小程序或回收机上进行账户登陆，即可实现垃圾投递，实时获取环保金。环保金是小松鼠对用户参与垃圾分类回收的一种资金奖励，根据用户投放种类和数量，发放相应的环保金。用户可对环保金进行微信提现或参与活动
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">用户端小程序</div>
            <div class="show-title-p" style="margin-top: 10px">
              通过小程序可以进行开箱投递，环保金提现，参与活动等
            </div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-4.png" alt="" />
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">回收员小程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-5.png" alt="" />
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">回收站小程序</div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-6.png" alt="" />
            </div>
          </div>
          <div>
            <div class="item-color show-title-third">回收商小程序</div>
            <div class="show-title-p" style="margin-top: 10px">
              回收商预充值到小松鼠平台，回收商通过平台支付回收员交货的货款。精准掌握收货量与货款数据。
            </div>
            <div class="show-title-img">
              <img src="../../assets/img/software/software-7.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="show show-margin" v-if="asideTab == 5">
        <div class="show-title">
          <div class="show-title-t1">干湿垃圾系统</div>
          <div class="show-title-p">
            通过系统管理用户的垃圾投递行为，对用户的投递信息进行记录和上报。包括是否合规投递垃圾、投递重量、积分获得，能够按设备点位划分不同区域，进行一体化的数据呈现及分析管理。
          </div>
          <div class="show-title-img" style="margin-bottom: 10px">
            <img src="../../assets/img/software/software-8.png" alt="" />
          </div>
          <div class="show-title-img">
            <img src="../../assets/img/software/software-9.png" alt="" />
          </div>
        </div>
      </div>
      <div class=""></div>
      <div class="aside-nav" :style="top">
        <div class="aside-nav-li aside-nav-li-sp row-start">
          <div>软件产品</div>
          <div class="aside-nav-sp-t1">精灵小松鼠</div>
        </div>
        <div
          class="aside-nav-li row-between"
          v-for="item in asideNav"
          :key="item.id"
          :class="asideTab == item.id ? 'aside-nav-li-active' : ''"
          @click="asideChange(item.id)"
        >
          <div class="aside-nav-t1">{{ item.name }}</div>
          <div class="aside-nav-t2">
            <img src="../../assets/img/news-more1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: "软件产品",
      top: "",
      asideNav: [
        {
          id: 0,
          name: "软件介绍",
        },
        {
          id: 1,
          name: "SaaS系统",
        },
        {
          id: 2,
          name: "政府垃圾分类地方管理系统",
        },
        {
          id: 3,
          name: "大数据平台",
        },
        {
          id: 4,
          name: "可回收物系统",
        },
        {
          id: 5,
          name: "干湿垃圾系统",
        },
      ],
      asideTab: 0,
      sassBg: [
        {
          id: 0,
          bg: require("../../assets/img/software/software-sass1.png"),
        },
        {
          id: 1,
          bg: require("../../assets/img/software/software-sass2.png"),
        },
        {
          id: 2,
          bg: require("../../assets/img/software/software-sass3.png"),
        },
        {
          id: 3,
          bg: require("../../assets/img/software/software-sass4.png"),
        },
        {
          id: 4,
          bg: require("../../assets/img/software/software-sass5.png"),
        },
      ],
      sassIndex: 0,
      sassLi: [
        {
          id: 0,
          img: require("../../assets/img/software/software-sass-a1.png"),
          name: "用户数据",
        },
        {
          id: 1,
          img: require("../../assets/img/software/software-sass-a2.png"),
          name: "投递行为统计",
        },
        {
          id: 2,
          img: require("../../assets/img/software/software-sass-a3.png"),
          name: "小区排名",
        },
        {
          id: 3,
          img: require("../../assets/img/software/software-sass-a4.png"),
          name: "智能监控",
        },
        {
          id: 4,
          img: require("../../assets/img/software/software-sass-a5.png"),
          name: "设备近日情况",
        },
        {
          id: 5,
          img: require("../../assets/img/software/software-sass-a6.png"),
          name: "区域休息",
        },
        {
          id: 6,
          img: require("../../assets/img/software/software-sass-a7.png"),
          name: "实时监控显示",
        },
        {
          id: 7,
          img: require("../../assets/img/software/software-sass-a8.png"),
          name: "定制化大屏",
        },
      ],
      business: [
        {
          id: 0,
          img: require("../../assets/img/software/software-gov-a1s.png"),
          bgImg: require("../../assets/img/software/software-gov-a1.png"),
          featureName: "物联网技术",
        },
        {
          id: 1,
          img: require("../../assets/img/software/software-gov-a2s.png"),
          bgImg: require("../../assets/img/software/software-gov-a2.png"),
          featureName: "数据上报",
        },
        {
          id: 2,
          img: require("../../assets/img/software/software-gov-a3s.png"),
          bgImg: require("../../assets/img/software/software-gov-a3.png"),
          featureName: "投递监测",
        },
        {
          id: 3,
          img: require("../../assets/img/software/software-gov-a4s.png"),
          bgImg: require("../../assets/img/software/software-gov-a4.png"),
          featureName: "数据追溯",
        },
      ],
    };
  },

  mounted: function () {
    window.addEventListener("scroll", this.scrollToTop, true);
    
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    scrollToTop() {
      var asideTab = this.asideTab;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
      switch (asideTab) {
        case 0:
          if (scrollTop > 200 && scrollTop < 6300) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 6300) {
            this.top = "top:6100px";
          } else {
            this.top = 0;
          }
          break;
        case 1:
          if (scrollTop > 200 && scrollTop < 700) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 700) {
            this.top = "top:500px";
          } else {
            this.top = 0;
          }
          break;
        case 2:
          if (scrollTop > 200 && scrollTop < 924) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 924) {
            this.top = "top:724px";
          } else {
            this.top = 0;
          }
          break;
          case 3:
          if (scrollTop > 200 && scrollTop < 500) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 500) {
            this.top = "top:300px";
          } else {
            this.top = 0;
          }
          break;
        case 4:
          if (scrollTop > 200 && scrollTop < 2864) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 2864) {
            this.top = "top:2664";
          } else {
            this.top = 0;
          }
          break;
        case 5:
          if (scrollTop > 200 && scrollTop < 861) {
            this.top = "top:" + (scrollTop * 1 - 200) + "px";
          } else if (scrollTop > 861) {
            this.top = "top:661";
          } else {
            this.top = 0;
          }
          break;
      }
    },
    asideChange(e) {
      this.asideTab = e;
    },
    sassBgChange(e) {
      this.sassIndex = e;
    },
  },
};
</script>

<style>
.software-banner {
  background-image: url("../../assets/img/software/software-banner.png");
}
.show-title-img-sps {
  width: 798px;
  height: 451px;
  margin-bottom: 10px;
}
.show-shadow-sp3 {
  width: 199px !important;
  height: 148px !important;
}
.show-shadow-bg-t3 {
  width: 199px !important;
  height: 148px !important;
}
.show-shadow {
  width: 151px;
  height: 112px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.show-shadow-bg {
  width: 151px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #0285ff 0%, #02aafe 100%);
  z-index: 99;
  margin-left: -500px;
  transition: all 0.3s linear;
}
.show-shadow:hover .show-shadow-bg {
  margin-left: 0;
  transition: all 0.3s linear;
}

.show-shadow-img {
  width: 28px;
  height: 28px;
  margin-bottom: 4px;
}
.show-shadow-t1 {
  font-size: 12px;
  /* font-family: MicrosoftYaHei-Bold, MicrosoftYaHei; */
  font-weight: bold;
  color: #ffffff;
}
.operate {
  width: 199px;
  text-align: center;
}
.operate-img {
  width: 199px;
  height: 148px;
  background: #dde8f4;
  margin-bottom: 10px;
}
.case {
  width: 199px;
  text-align: center;
}
.case-img {
  width: 199px;
  height: 148px;
  margin-bottom: 12px;
}
</style>